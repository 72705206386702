import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchContentfulTalentAppData } from "../../store/actions/contentfulTalentAppDataActions";
import TalentsApiService from "../../TalentsApiService";
import * as Constants from "../../constants";

import PageLayout from "../../components/layoutComponents/PageLayout";
import Loading from "../../components/reusableElementsComponents/Loading";
import OnboardingStepper from "../../components/layoutComponents/navigation/OnboardingStepper";
import MazhrModal from "../../components/reusableElementsComponents/MazhrModal";
import { SalesSelect } from "../../components/formComponents/Inputs";

import {
  FormControlLabel,
  Checkbox,
  Typography,
  Alert,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import assessmentCompletedCheckmark from "../../assets/images/icons/assessmentCompletedCheckmark.png";
import assessmentCardBackgroundPlaceholder from "../../assets/images/backgrounds/assessmentCardBackground-102.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AssessmentIcon from '@mui/icons-material/Assessment';
import DownloadIcon from '@mui/icons-material/Download';
import RedoIcon from '@mui/icons-material/Redo';
import {Circle as CircleIcon} from "@mui/icons-material";

function RegistrationStage3() {
  const [loader, setLoader] = useState(false);
  const [defaultAssessments, setDefaultAssessments] = useState([]); // [ {fields: {instrumentId: 102, titleDesktop : "Personality and behaviour"}}, ...]
  const [requiredAssessments, setRequiredAssessments] = useState([]); // [102, 201, ...]
  const [completedAssessments, setCompletedAssessments] = useState([]); // [{ "score_url": "https://ws01.maptq.com/..", "assessment_name": "shapes", "instrument_id": 102 }, ...]
  const [assessmentToBeRetaken, setAssessmentToBeRetaken] = useState(null);
  const [openRetakeDialog, setOpenRetakeDialog] = useState(false);
  const [languageChoice, setLanguageChoice] = useState(null);
  const [rememberChoice, setRememberChoice] = useState(false);
  /**
   * Get the Talent App data from the Redux store
   * If it doesn't exist, fetch it from Contentful
   */
  let pageData = useSelector(
    (state) =>
      state.contentfulData?.talentAppData?.[
        "Talent app - Registration page (stage 3)"
      ]
  );
  const assessmentData = useSelector(
    (state) => state.contentfulData?.assessments
  );
  const userCompletedAssessments =
    useSelector((state) => state.userData?.all?.assessment_report_list) || null;

  const poolRequiredAssessments =
      useSelector((state) => state.poolData?.requiredAssessments) || null;

  const teamRequiredAssessments =
    useSelector((state) => state.teamData?.requiredAssessments) || null;

  const allAssessmentsCompleted =
    useSelector((state) => state.userData?.allAssessmentsCompleted) || false;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!pageData && !assessmentData) {
      setLoader(true);
      dispatch(fetchContentfulTalentAppData());
    } else {
      let assessmentArray = Object.keys(assessmentData).map((key) => {
        let assessment = assessmentData[key];
        const { images, ...rest } = assessment?.fields;
        const backgroundImages = Object.keys(assessment?.images || {}).map(
          (key) => {
            return assessment?.images[key];
          }
        );
        return { ...rest, backgroundImages };
      });

      setDefaultAssessments(assessmentArray);
      setLoader(false);
    }
  }, [pageData, assessmentData, dispatch]);

  useEffect(
    () => {
      setLanguageChoice(Constants.languageSelectOptions[1]);
    }, // eslint-disable-next-line
    []
  ); // Set default language choice only once at the beginning. Only run once to avoid endless loop

  useEffect(
    () => {
      setLanguageChoice(Constants.languageSelectOptions[1]);
    }, // eslint-disable-next-line
    []
  ); // Set default language choice only once at the beginning. Only run once to avoid endless loop

  /**
   * Get the user invitation params from the local storage
   * Then load the team by the hash and get all the required assessment ids
   * Then filter the required assessments from the Contentful assessment list
   *
   * Also get the user details and the completed assessments
   */

  const talentsServiceObj = useMemo(() => {
    return new TalentsApiService();
  }, []);

  useEffect(() => {
    const requiredAssessments = poolRequiredAssessments.concat(teamRequiredAssessments)

    if (requiredAssessments?.length >= 0) {
      setRequiredAssessments(requiredAssessments);
    } else {
      setRequiredAssessments(defaultAssessments.map((x) => x.instrumentId));
    }

    if (userCompletedAssessments?.length >= 0) {
      setCompletedAssessments(userCompletedAssessments);
    }

  }, [poolRequiredAssessments, teamRequiredAssessments, defaultAssessments, userCompletedAssessments]); // Only run once to avoid endless loop

  // Handle "Retake the test" button click
  const handleStartTest = (providerName, instrumentId, languageId) => {
    talentsServiceObj
      .GetAssessmentUrl(
        providerName || "aon",
        instrumentId || 102,
        languageId || 2
      )
      .then((res) => {
        if (res.httpStatus === 200 && res.json && res.json.data) {
          const url = res.json.data?.url;
          if (url && url.includes("https://", 0)) {
            window.location.assign(url);
          } else {
            console.log("GetAssessmentUrl url is not valid  >> ", url);
          }
        } else {
          console.log("GetAssessmentUrl error  >> ", res);
        }
      });
  };

  if (!loader && pageData?.fields) {
    let { titleDesktop, subtitleDesktop } = pageData?.fields;
    return (
      <div className="bg-cover">
        <PageLayout>
          <div className="assessment-page-wrapper">
            <h1>{titleDesktop || "Assessments"}</h1>
          </div>
        </PageLayout>
        <PageLayout>
          {defaultAssessments?.length > 0 && userCompletedAssessments ? (
            <>
              <OnboardingStepper
                activeStepNumber={teamRequiredAssessments ? 2 : 3}
                isTeamMember={teamRequiredAssessments ? true : false}
                disabled={allAssessmentsCompleted ? true : false}
              />
              <div className="assessment-page-wrapper">
                <h5 className="grey s login-form-text-left">
                  {subtitleDesktop || "Takes 15-20 min per assessment."}
                </h5>
                {renderAssessmentProgress(
                  requiredAssessments || [],
                  completedAssessments || []
                )}

                {renderAllAssessments(
                  defaultAssessments || [],
                  requiredAssessments || [],
                  completedAssessments || [],
                  setAssessmentToBeRetaken,
                  setOpenRetakeDialog
                )}
              </div>
            </>
          ) : (
            <Loading sectionLoading containerHeight="50vh" />
          )}
        </PageLayout>

        <MazhrModal
          show={openRetakeDialog}
          onHide={() => setOpenRetakeDialog(false)}
          header={<h3 style={{color: '#C13F8A', textAlign:'left', margin:'0',padding:'0'}}>
            Retake Assessment
          </h3>}
        >
          <div className="mar-35 left">
            <ul>
              <li>
                <Typography variant="h5" className="test-instruction-heading">
                  Instructions
                </Typography>
                <List>
                  {assessmentToBeRetaken?.instruction
                      ? assessmentToBeRetaken?.instruction.split("-").map((item, index) => (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <CircleIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={item.trim()} />
                          </ListItem>
                      ))
                      : (
                          <Typography variant="body2">
                            No instructions available.
                          </Typography>
                      )}
                </List>
              </li>
              <li className="mar-15 top">
                <b>{"Select test language"}</b>
              </li>
              <li className="mar-15 top">
                <SalesSelect
                  label=""
                  options={Constants.languageSelectOptions}
                  handleChange={(e) => {
                    const { value } = e.target;
                    setLanguageChoice(
                        Constants.languageSelectOptions.find((x) => x.title === value)
                    );
                  }}
                  errorContent={"Please select group"}
                  option={languageChoice?.title}
                  inputProps={{ style: {} }}
                />
              </li>
              <li className="mar-15 top">
                <FormControlLabel
                  className="test-instruction-select-language"
                  control={
                    <Checkbox
                      checked={rememberChoice}
                      name="remeber_language_choice"
                      onChange={(e) => {
                        const { checked } = e.target;
                        setRememberChoice(checked);
                      }}
                    />
                  }
                  label={<span>{"Remember my choice for later tests"}</span>}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                />
              </li>
            </ul>
          </div>
          <Alert severity="warning" style={{backgroundColor: '#FDECEF', color: '#C13F8A'}}>
            <Typography variant="h6" style={{color: '#C13F8A'}}>
              <strong>Pressing Retake Button will reset results for this assessment.</strong>
            </Typography>
          </Alert>
          <Divider sx={{marginBottom: 2}}/>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <button
              className="mazhrBtn default-green-border"
              onClick={() => setOpenRetakeDialog(false)}
            >
              Cancel
            </button>
            <button
              className="mazhrBtn default-green mar-20 left"
              style={{backgroundColor:'#FFBB31'}}
              onClick={() =>
                assessmentToBeRetaken &&
                handleStartTest(
                  "aon",
                  assessmentToBeRetaken?.instrumentId,
                  languageChoice?.code
                )
              }
            >
              Retake
            </button>
          </div>
        </MazhrModal>
      </div>
    );
  } else {
    return <Loading />;
  }
}

const getCompletionPercentage = (completed = 0, uncompleted = 1) =>
  `${((completed / uncompleted) * 100).toFixed(0)}%`;

const renderAssessmentProgress = (
  requiredAssessments,
  completedAssessments
) => {
  const requiredNumber =
    requiredAssessments?.length > 0 ? requiredAssessments?.length : null;

  let completedNumber = 0
  for(let index in completedAssessments){
    if(requiredAssessments.includes(completedAssessments[index]?.instrument_id)){
      completedNumber++
    }
  }

  return (
    requiredNumber && (
      <div className="assessment-progress">
        <div>
          <div>
            You’ve taken {completedNumber} of {requiredNumber} required
            assessments{" "}
            <LightTooltip
              placement="top-end"
              title="Your team ask you to complete these assessments before proceeding"
            >
              <InfoOutlinedIcon className="required-note-icon" />
            </LightTooltip>
          </div>
          <div className="assessment-progress-slider">
            <div className="assessment-progress-bar">
              <div
                className="assessment-progress-bar-fill"
                style={{
                  width: getCompletionPercentage(
                    completedNumber,
                    requiredNumber
                  ),
                }}
              />
            </div>
            <div className="assessment-progress-bar-indicator">
              {getCompletionPercentage(completedNumber, requiredNumber)}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const renderAllAssessments = (
    defaultAssessments,
    requiredAssessments,
    completedAssessments,
    setAssessmentToBeRetaken,
    setOpenRetakeDialog
) => {
  const categories = {};
  const scoreUrlsByInstrumentId = completedAssessments.reduce((acc, assessment) => {
    acc[assessment.instrument_id] = assessment.score_url;
    return acc;
  }, {});

  defaultAssessments
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .forEach((assessment) => {
        const required = requiredAssessments.includes(assessment.instrumentId);
        const completed = completedAssessments.some(
            (y) => y.instrument_id === assessment.instrumentId
        );

        const assessmentCard = {
          ...assessment,
          required,
          completed,
        };

        if (!categories[assessment.category]) {
          categories[assessment.category] = [];
        }

        if(required)
          categories[assessment.category].push(assessmentCard);
      });

  return Object.entries(categories).map(([category, assessments], index) => {
    if (assessments.length === 0) return null; // Skip categories with no assessments

    return (
        <div key={index} className="mar-25 bottom">
          <h3>{category}</h3>
          <div className="assessment-card-wrapper">
            {assessments.map((card) => (
                <div className="assessment-card" key={card.instrumentId}>
                  <div
                      className={`assessment-card-header card-background-${card.instrumentId}`}
                      style={{
                        backgroundImage: `url(${
                            card.backgroundImages[0] ||
                            assessmentCardBackgroundPlaceholder
                        })`,
                      }}
                  >
                    <h3>{card.titleDesktop}</h3>
                    <p>{card.subtitleDesktop}</p>
                    {card.completed ? (
                        <div className="assessment-card-notification-wrapper">
                          <img
                              className="assessment-card-completed-icon"
                              src={assessmentCompletedCheckmark}
                              alt="background"
                          />
                        </div>
                    ) : card.required ? (
                        <LightTooltip
                            placement="top-end"
                            title="Please complete this assessment to proceed further"
                        >
                          <div className="assessment-card-notification-wrapper required-note">
                            <InfoOutlinedIcon /> Required
                          </div>
                        </LightTooltip>
                    ) : null}
                  </div>
                  {card.completed ? (
                      <div className="assessment-completed-card-body">
                        <div className="assessment-card-cta-wrapper">
                          <p>
                            <Link
                                className="m bold underline"
                                to={card.pageUrlPath}
                            >
                              View your results
                              &nbsp;<AssessmentIcon/>
                            </Link>
                          </p>
                          <p>
                            <Link
                                className="m bold underline"
                                to={scoreUrlsByInstrumentId[card.instrumentId]}
                            >
                              Download results as PDF
                              &nbsp;<DownloadIcon/>
                            </Link>
                          </p>
                          <p>
                            <Link
                                className="m bold underline"
                                onClick={() => {
                                  setAssessmentToBeRetaken(card);
                                  setOpenRetakeDialog(true);
                                }}
                            >
                              Retake the test
                              &nbsp;<RedoIcon/>
                            </Link>
                          </p>
                        </div>
                      </div>
                  ) : (
                      <div className="assessment-card-body">
                        <p>{card.shortDescription}</p>
                        <div className="assessment-card-cta-wrapper">
                          <Link
                              className="mazhrBtn default-green white"
                              to={card.pageUrlPath}
                          >
                            Do this assessment
                            {" >>"}
                          </Link>
                        </div>
                      </div>
                  )}
                </div>
            ))}
          </div>
        </div>
    );
  });
};


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    border: "1px solid red",
    fontSize: 14,
  },
}));

export default RegistrationStage3;
